import React, { useState } from "react";
import axios from "axios";

import Input from "./common/Input";

const ABEContact = ({ formId }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    practiceName: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({
    fullName: null,
    practiceName: null,
    email: null,
    mobile: null,
  });
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [isDisplayForm, setIsDisplayForm] = useState(true);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Clear the error
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const validateInput = () => {
    let newErrors = {};

    // Validate the fullName field
    if (!formData.fullName) {
      newErrors.fullName = "Full name is required";
    }

    // Validate the practiceName field
    if (!formData.practiceName) {
      newErrors.practiceName = "Practice name is required";
    }

    // Validate the email field
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email address is invalid";
    }

    // Validate the mobile field
    if (!formData.mobile) {
      newErrors.mobile = "Mobile is required";
    } else if (!/^(?:\+?61|0)4\d{8}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number is invalid";
    }

    // If there are errors, set the errors object in state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsDisableSubmit(true);

    const isInputValid = validateInput();
    if (!isInputValid) {
      return;
    }

    const baseAPIURL =
      "https://hb7lwijvspytknrzmbdskqlk7e0vldou.lambda-url.ap-southeast-2.on.aws/";

    const ApiRes = await axios.post(baseAPIURL, formData);

    // Send Slack notification
    const webhookUrl =
      "https://hooks.slack.com/services/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";
    const message =
      `--------------------------------------\n` +
      `*ABE24 Promotion Request*\n` +
      `*Full Name:* ${formData.fullName}\n` +
      `*Practice Name:* ${formData.practiceName}\n` +
      `*Email:* ${formData.email}\n` +
      `*Mobile:* ${formData.mobile}\n` +
      `--------------------------------------`;

    const data = { text: message };

    try {
      let slackRes = await axios.post(webhookUrl, data, {
        withCredentials: false,
        transformRequest: [
          (data) => {
            return JSON.stringify(data);
          },
        ],
      });

      if (ApiRes.status === 200 && slackRes.status === 200) {
        setIsDisplayForm(false);
      }
    } catch (error) {
      console.error("Error sending Slack notification:", error);
    } finally {
      setIsDisableSubmit(false);
    }
  };

  return (
    <div className="switch-contact-c" id={formId}>
      <div className="content">
        <div className="switch-contact-grid">
          <div className="switch-contact-left">
            <div className="abe-contact-left-title">ABE24 SPECIAL OFFER</div>

            <div className="abe-contact-left-subtitle">
              Sign up to AuditCover and receive 10% off your first year!*
            </div>

            <div className="switch-contact-desc">
              <div className="switch-contact-left-icon">
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  loading="lazy"
                />
              </div>
              Dedicated tax audit insurance specialist
            </div>

            <div className="switch-contact-desc">
              <div className="switch-contact-left-icon">
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  loading="lazy"
                />
              </div>
              Telephone and email support
            </div>

            <div className="switch-contact-desc">
              <div className="switch-contact-left-icon">
                <img
                  src="/images/new-landing-page/tick-icon.svg"
                  alt="tick"
                  loading="lazy"
                />
              </div>
              Up and running in a few days with no further work needed
            </div>
          </div>

          <div className="switch-contact-right">
            {isDisplayForm ? (
              <form onSubmit={handleFormSubmit}>
                <Input
                  type="text"
                  name="fullName"
                  className="switch-contact-input"
                  placeholder="Full name"
                  value={formData.fullName}
                  error={errors.fullName}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="practiceName"
                  className="switch-contact-input"
                  placeholder="Practice name"
                  value={formData.practiceName}
                  error={errors.practiceName}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="email"
                  className="switch-contact-input"
                  placeholder="Email"
                  value={formData.email}
                  error={errors.email}
                  onChange={handleChange}
                />
                <Input
                  type="text"
                  name="mobile"
                  className="switch-contact-input"
                  placeholder="Mobile"
                  value={formData.mobile}
                  error={errors.mobile}
                  onChange={handleChange}
                />
                <button
                  className="switch-submit-btn"
                  disabled={isDisableSubmit}
                >
                  Submit
                </button>
              </form>
            ) : (
              <div className="form-success-msg">
                Thanks for the request! We will get in touch with you shortly.
              </div>
            )}
          </div>
        </div>

        <div className="abe-contact-bottom">
          *Sign up with AuditCover and launch before the end of April to secure
          a discount equal to 10% of the base premium for your clients who
          purchase a policy during your first year with us.
        </div>
      </div>
    </div>
  );
};

export default ABEContact;
