import React from "react";

import Layout from "../components/Layout";

import ABEContact from "../components/ABEContact";

const ABE24Page = () => {
  return (
    <Layout bg="home-bg" isDisplayBottomPartner={false} isDisplayHeader={false}>
      <ABEContact formId="abe-contact-form" />
    </Layout>
  );
};

export default ABE24Page;
